.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.MuiInputBase-formControl {
  font-size: 0.85rem !important;
}
.RaEdit-card,
.RaShow-card {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 0 !important;
}
.RaEdit-card .MuiPaper-root {
  width: 100%;
}
/* .MuiFormControl-fullWidth {
  margin-top: 0 !important;
} */
.MuiCardContent-root {
  padding: 0 !important;
}
.MuiCard-root {
  border-radius: 10px !important;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important; */
}
.RaLayout-contentWithSidebar {
  background-color: #394382;
}
.MuiPaper-root {
  box-shadow: none !important;
}
.RaList-main .MuiCard-root {
  border-radius: 0 !important;
  box-shadow: none !important;
}
.RaLayout-content {
  background-color: #f2f2ff !important;
}
.RaShowClass {
  padding: 0 !important;
}
.actionMenu a,
.actionMenu button {
  width: 100%;
}
.MuiToolbar-regular {
  background-color: transparent !important;
}
.RaDatagrid-root .MuiToolbar-regular {
  background-color: #f2f2f2f9 !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.MuiToolbar-regular {
  min-height: 0 !important;
  padding-top: 0 !important;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Apply custom styles to unordered lists within RichTextInput */
.richTextBody ul {
  list-style-type: disc;
  margin: 0 0 1em; /* Adjust margin for better spacing */
  padding: 0 0 0 1em; /* Add indentation for sub-lists */
}

/* Apply styles to list items within unordered lists */
.MuiCardContent-root li {
  margin: 0; /* Reset margin for list items */
}

/* Apply styles to nested unordered lists */
.MuiCardContent-root ul ul {
  list-style-type: circle;
}
.MuiCard-Content-root .MuiPagination-ul {
  list-style-type: none !important;
}

/* Apply styles to nested list items */
.MuiCardContent-root ul ul li {
  margin: 0; /* Reset margin for nested list items */
}

#main-content {
  padding: 30px;
}
.RaSidebar-fixed li span,
.RaSidebar-fixed li svg {
  color: rgb(233, 233, 233) !important;
}

.RaSidebar-fixed li span:hover,
.RaSidebar-fixed li svg:hover {
  color: rgb(255, 255, 255) !important;
}
.RaSidebar-paper,
.RaSidebar-fixed {
  background-color: #394382 !important;
  color: #fff !important;
}
.MuiTable-root {
  min-width: 420px;
}
.RaDatagrid-tableWrapper,
.isMobile .MuiCardContent-root {
  overflow-x: scroll;
}
.isMobile #main-content {
  margin-top: 30px;
}
.RaDatagrid-tableWrapper .MuiTableCell-body {
  max-width: 200px;
}

.RaDatagrid-tableWrapper .rowUserBlock {
  /* background-color: #f5f5f5; */
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #f5f5f5;
  padding: 0 3px;
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
    border-color: #eaeaea;
  }
}

.RaDatagrid-tableWrapper .rowUserBlock .MuiSvgIcon-fontSizeMedium,
.RaDatagrid-tableWrapper .rowUserBlock .MuiSvgIcon-fontSizeSmall {
  font-size: 12px !important;
}

@media (min-width: 900px) {
  .RaList-main .RaList-actions .MuiToolbar-root {
    position: absolute;
    top: 0;
  }
  .RaList-main .RaFilterForm-filterFormInput {
    margin-top: 30px;
  }
}
